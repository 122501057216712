
import * as directusRuntime$Ndom0aH0s0 from 'C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_rollup@4.32.0/node_modules/@nuxt/image/dist/runtime/providers/directus'
import * as ipxRuntime$ZE6wxbsFhX from 'C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_rollup@4.32.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['directus']: { provider: directusRuntime$Ndom0aH0s0, defaults: {"baseURL":"https://backend.flexi-solutions.net/assets/"} },
  ['ipx']: { provider: ipxRuntime$ZE6wxbsFhX, defaults: {} }
}
        